//サイトレイアウト構造を記述

// #container {}
// #main {}
// #contents {
//   padding: 0;
//   @include mq-max(sm) {
//     width: $full-size;
//     padding: 0;
//   }
// }

// //gridlex調整
// [class*="grid"] {
//   margin-bottom: -1 * $contentPadding; //gridlexを使うことでできてしまう下余白を相殺。
// }
// [class*="col-"] {
//   img {
//     width: 100%;
//     display: block;
//   }
// }

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  基本ブロックレイアウト

///////////////////////////////////////////////////////////////////////////////////////////////////////
.l-section {
  width: 100%;
  max-width: $contentWidth;
  margin: 0 auto;
  padding: 60px 0;
  @media screen and (max-width:$contentWidth + $contentPadding) {
    padding: 0 $contentPadding 60px;
  }
  @include mq-max(sm) {
    padding: 0 $contentPadding/2 60px;//お好みで変更
  }
  &__inner {
    padding: 0 0 60px;//お好みで変更
    &:last-child {
      padding-bottom: 0;
    }
  }

  &--medium {
    max-width: 80%;
    margin: 0 auto;
    @include mq-max(xs) {
      max-width: 100%;
    }
  }
  &--small {
    max-width: 50%;
    margin: 0 auto;
    @include mq-max(xs) {
      max-width: 100%;
    }
  }

  //grid調整(デフォで付く下余白をマージンで相殺)
  [class*="grid"] {
    margin-bottom: -1 * $contentPadding;
  }
}

//background full width
@mixin bg_scss() {
  @include mq-max(content) {
    margin-bottom: 60px;;
  }
  &>.l-section {
    @include mq-max(content) {
      padding-top: 60px;
    }
  }
}

.l-section--keyColor {
  background-color: $keyColor;
  @include bg_scss();
}

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  text-align

///////////////////////////////////////////////////////////////////////////////////////////////////////
.u-taL {
  text-align: left !important;
}
.u-taC {
  text-align: center !important;
  &--xsL {
    @include mq-max(xs) {
      text-align: left !important;
    }
  }
}
.u-taR {
  text-align: right !important;
}

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  only系

///////////////////////////////////////////////////////////////////////////////////////////////////////
.u-only-xs {
  display: none !important;
  @include mq-max(xs) {
    display: block !important;
  }
}
.u-only-sm {
  display: none !important;
  @include mq-max(sm) {
    display: block !important;
  }
  @include mq-max(xs) {
    display: none !important;
  }
}
.u-only-md {
  display: none !important;
  @include mq-max(md) {
    display: block !important;
  }
  @include mq-max(sm) {
    display: none !important;
  }
}
.u-only-lg {
  @include mq-max(md) {
    display: none !important;
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  ページ内アンカー位置調整

///////////////////////////////////////////////////////////////////////////////////////////////////////
// .anchorReposition {
//   padding-top: $headerHeight + 10px;
//   margin-top: -1 * $headerHeight - 10px;
//   @include mq-max(md) {
//     padding-top: $headerHeight-sp + 10px;
//     margin-top: -1 * $headerHeight-sp - 10px;
//   }
// }

///////////////////////////////////////////////////////////////////////////////////////////////////////

//  layout

///////////////////////////////////////////////////////////////////////////////////////////////////////


