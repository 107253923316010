//headerカスタマイズ
.l-header {
  h1 {
    margin: 0;
    line-height: 1;
  }
  .logo {
    max-width: 290px;
    a {
      img {
        width: 100% !important;
      }
    }
  }

  //スクロールしたら出てくるheader
  &.headroom--sticky.headroom--not-top {
    background-color: rgba($white,.5);
    @include mq-max(bp991) {
      background-color: rgba($white,1);
    }
  }
}