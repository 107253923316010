@charset "UTF-8";
/*
ユーティリティ系おまとめファイル
*/
/*
██████   █████  ███████ ███████
██   ██ ██   ██ ██      ██
██████  ███████ ███████ █████
██   ██ ██   ██      ██ ██
██████  ██   ██ ███████ ███████
*/
/*
██████  ██████  ███████  █████  ██   ██ ██████   ██████  ██ ███    ██ ████████
██   ██ ██   ██ ██      ██   ██ ██  ██  ██   ██ ██    ██ ██ ████   ██    ██
██████  ██████  █████   ███████ █████   ██████  ██    ██ ██ ██ ██  ██    ██
██   ██ ██   ██ ██      ██   ██ ██  ██  ██      ██    ██ ██ ██  ██ ██    ██
██████  ██   ██ ███████ ██   ██ ██   ██ ██       ██████  ██ ██   ████    ██
*/
/*
 ██████  ██████  ██       ██████  ██████
██      ██    ██ ██      ██    ██ ██   ██
██      ██    ██ ██      ██    ██ ██████
██      ██    ██ ██      ██    ██ ██   ██
 ██████  ██████  ███████  ██████  ██   ██
*/
/*
████████ ███████ ██   ██ ████████
   ██    ██       ██ ██     ██
   ██    █████     ███      ██
   ██    ██       ██ ██     ██
   ██    ███████ ██   ██    ██
*/
body {
  font-family: YakuHanJPs, "Noto Sans JP", 游ゴシック体, YuGothic-M, "Yu Gothic", YuGothic, "ヒラギノ角ゴシック Pro", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  word-wrap: break-word;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-feature-settings: "pwid"; }

h2, h3, h4, h5, h6 {
  line-height: 1.5;
  font-family: YakuHanJPs, "Noto Sans JP", 游ゴシック体, YuGothic-M, "Yu Gothic", YuGothic, "ヒラギノ角ゴシック Pro", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif; }

p {
  font-size: 16px;
  margin-bottom: 1.3em;
  line-height: 2; }
  p:last-child {
    margin-bottom: 0; }
  @media screen and (max-width: 568px) {
    p {
      font-size: 15px; }
      p:last-child {
        margin-bottom: 0; } }

strong {
  font-weight: 600; }

figure img {
  width: 100%; }

ul, ol {
  list-style-type: none; }

.l-section {
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
  padding: 60px 0; }
  @media screen and (max-width: 1200px) {
    .l-section {
      padding: 0 30px 60px; } }
  @media screen and (max-width: 768px) {
    .l-section {
      padding: 0 15px 60px; } }
  .l-section__inner {
    padding: 0 0 60px; }
    .l-section__inner:last-child {
      padding-bottom: 0; }
  .l-section--medium {
    max-width: 80%;
    margin: 0 auto; }
    @media screen and (max-width: 568px) {
      .l-section--medium {
        max-width: 100%; } }
  .l-section--small {
    max-width: 50%;
    margin: 0 auto; }
    @media screen and (max-width: 568px) {
      .l-section--small {
        max-width: 100%; } }
  .l-section [class*="grid"] {
    margin-bottom: -30px; }

.l-section--keyColor {
  background-color: #2ec183; }
  @media screen and (max-width: 1200px) {
    .l-section--keyColor {
      margin-bottom: 60px; } }
  @media screen and (max-width: 1200px) {
    .l-section--keyColor > .l-section {
      padding-top: 60px; } }

.u-taL {
  text-align: left !important; }

.u-taC {
  text-align: center !important; }
  @media screen and (max-width: 568px) {
    .u-taC--xsL {
      text-align: left !important; } }

.u-taR {
  text-align: right !important; }

.u-only-xs {
  display: none !important; }
  @media screen and (max-width: 568px) {
    .u-only-xs {
      display: block !important; } }

.u-only-sm {
  display: none !important; }
  @media screen and (max-width: 768px) {
    .u-only-sm {
      display: block !important; } }
  @media screen and (max-width: 568px) {
    .u-only-sm {
      display: none !important; } }

.u-only-md {
  display: none !important; }
  @media screen and (max-width: 1024px) {
    .u-only-md {
      display: block !important; } }
  @media screen and (max-width: 768px) {
    .u-only-md {
      display: none !important; } }

@media screen and (max-width: 1024px) {
  .u-only-lg {
    display: none !important; } }

@font-face {
  font-family: "YakuHanJP";
  font-style: normal;
  font-weight: normal;
  src: url("/inc/font/YakuHanJP/YakuHanJP-Regular.eot");
  src: url("/inc/font/YakuHanJP/YakuHanJP-Regular.woff2") format("woff2"), url("/inc/font/YakuHanJP/YakuHanJP-Regular.woff") format("woff"); }

@font-face {
  font-family: "YakuHanJP";
  font-style: bold;
  font-weight: bold;
  src: url("/inc/font/YakuHanJP/YakuHanJP-Bold.eot");
  src: url("/inc/font/YakuHanJP/YakuHanJP-Bold.woff2") format("woff2"), url("/inc/font/YakuHanJP/YakuHanJP-Bold.woff") format("woff"); }

@font-face {
  font-family: "YakuHanJPs";
  font-style: normal;
  font-weight: normal;
  src: url("/inc/font/YakuHanJPs/YakuHanJPs-Regular.eot");
  src: url("/inc/font/YakuHanJPs/YakuHanJPs-Regular.woff2") format("woff2"), url("/inc/font/YakuHanJPs/YakuHanJPs-Regular.woff") format("woff"); }

@font-face {
  font-family: "YakuHanJPs";
  font-style: bold;
  font-weight: bold;
  src: url("/inc/font/YakuHanJPs/YakuHanJPs-Bold.eot");
  src: url("/inc/font/YakuHanJPs/YakuHanJPs-Bold.woff2") format("woff2"), url("/inc/font/YakuHanJPs/YakuHanJPs-Bold.woff") format("woff"); }

/*
モジュール系おまとめファイル
*/
.l-header h1 {
  margin: 0;
  line-height: 1; }

.l-header .logo {
  max-width: 290px; }
  .l-header .logo a img {
    width: 100% !important; }

.l-header.headroom--sticky.headroom--not-top {
  background-color: rgba(255, 255, 255, 0.5); }
  @media screen and (max-width: 991px) {
    .l-header.headroom--sticky.headroom--not-top {
      background-color: white; } }

@media screen and (max-width: 1280px) {
  .l-footer .copyright .copyright-left ul.bk-copyright-menu li {
    padding: 5px 8px; } }

.l-footer .copyright .copyright-right {
  white-space: nowrap; }

@media screen and (min-width: 1200px) {
  .mainmenu-wrapper .page_nav .mainmenu > li {
    margin-right: 10px; }
    .mainmenu-wrapper .page_nav .mainmenu > li:last-child {
      margin-right: 0; } }

.mainmenu-wrapper .page_nav .mainmenu > li > a {
  padding-right: 0px !important; }
  .mainmenu-wrapper .page_nav .mainmenu > li > a > span {
    color: #000000;
    text-shadow: 1px 1px 2px rgba(255, 255, 255, 0.5); }
  .mainmenu-wrapper .page_nav .mainmenu > li > a.current > span:after {
    width: 100% !important; }

.popup-mobile-manu .inner .mobileheader .logo a img {
  width: 180px; }

.brook-btn {
  font-family: YakuHanJPs, "Noto Sans JP", 游ゴシック体, YuGothic-M, "Yu Gothic", YuGothic, "ヒラギノ角ゴシック Pro", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif !important; }

.c-btn:hover {
  color: #FFFFFF;
  background-color: #2ec183; }
  .c-btn:hover:after {
    right: 10px; }

.c-btn:after {
  content: "\f054";
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  transition: right 0.2s ease; }

.l-btnArea {
  max-width: 1170px;
  margin: 40px auto;
  text-align: center; }
  @media screen and (max-width: 1024px) {
    .l-btnArea {
      margin: 20px auto; } }
  @media screen and (max-width: 768px) {
    .l-btnArea {
      width: 100%;
      margin: 0 auto;
      padding: 10px 0; } }

.c-btn.c-btnForm {
  display: inline-block;
  width: auto;
  min-width: 350px;
  padding: 20px 40px;
  margin-bottom: 10px;
  font-size: 130%;
  display: inline-block;
  background: #2ec183;
  text-align: center;
  border: 0px solid none;
  color: #FFFFFF;
  transition: 0.2s ease;
  border-radius: 3px;
  text-decoration: none;
  position: relative; }
  @media screen and (max-width: 768px) {
    .c-btn.c-btnForm {
      display: inline-block;
      min-width: inherit;
      width: 100%;
      margin: 0 0 20px 0; } }
  .c-btn.c-btnForm:focus {
    outline: none; }
  .c-btn.c-btnForm:hover {
    background: #1f8359; }
  .c-btn.c-btnForm.back {
    width: auto;
    line-height: 28px;
    min-width: inherit;
    background: #FFFFFF;
    border: 1px solid #ccc;
    color: #000000;
    font-size: 120%;
    padding-left: 60px;
    margin-right: 20px; }
    @media screen and (max-width: 768px) {
      .c-btn.c-btnForm.back {
        font-size: 96%;
        min-width: inherit;
        width: 50%;
        max-width: 100%;
        margin: 0 auto;
        padding: 10px 20px; } }
    .c-btn.c-btnForm.back:hover {
      background: #f2f2f2; }
    .c-btn.c-btnForm.back:before {
      content: "\f053";
      font-family: "Font Awesome 5 Free";
      font-weight: 600;
      position: absolute;
      top: 50%;
      left: 20px;
      transform: translateY(-50%);
      text-align: left; }
      @media screen and (max-width: 568px) {
        .c-btn.c-btnForm.back:before {
          left: 8px; } }
    .c-btn.c-btnForm.back:after {
      content: none; }

.c-cvArea {
  background-color: #FCB72B; }
  .c-cvArea__heading {
    font-size: 28px;
    font-family: YakuHanJPs, "Noto Sans JP", 游ゴシック体, YuGothic-M, "Yu Gothic", YuGothic, "ヒラギノ角ゴシック Pro", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif; }
    @media screen and (max-width: 991px) {
      .c-cvArea__heading {
        font-size: 22px; } }
  .c-cvArea .brook-btn {
    max-width: 314px;
    width: 100%; }

.c-tbl {
  max-width: 100%;
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 30px; }
  .c-tbl:last-child {
    margin-bottom: 0; }
  .c-tbl th, .c-tbl td {
    border: 1px solid #CCCCCC;
    padding: 15px 20px; }
  @media screen and (max-width: 568px) {
    .c-tbl__block tbody tr:not(:last-child) td {
      border-bottom: none; } }
  .c-tbl__block tbody tr th {
    width: 20%; }
    @media screen and (max-width: 568px) {
      .c-tbl__block tbody tr th {
        border-bottom: none !important;
        text-align: left; } }
  .c-tbl__block tbody tr td {
    width: 80%; }
  @media screen and (max-width: 568px) {
    .c-tbl__block tbody tr th, .c-tbl__block tbody tr td {
      display: block;
      width: 100%; } }
  @media screen and (max-width: 568px) {
    .c-tbl__compare {
      width: 100%; } }
  @media screen and (max-width: 568px) {
    .c-tbl__compare thead {
      display: none; } }
  .c-tbl__compare thead th {
    background-color: #2ec183; }
    .c-tbl__compare thead th.p_name {
      width: 22.5%; }
    .c-tbl__compare thead th.p_content {
      width: 40%; }
    .c-tbl__compare thead th.deposit_term {
      width: 22.5%; }
    .c-tbl__compare thead th.deposit_price {
      width: 15%; }
  @media screen and (max-width: 568px) {
    .c-tbl__compare tbody tr, .c-tbl__compare tbody td {
      display: block;
      width: 100%;
      position: relative; } }
  @media screen and (max-width: 568px) {
    .c-tbl__compare tbody tr {
      margin-bottom: 25px; } }
  @media screen and (max-width: 568px) {
    .c-tbl__compare tbody tr:last-child {
      margin-bottom: 0; } }
  .c-tbl__compare tbody td {
    vertical-align: middle;
    font-size: 16px; }
    @media screen and (max-width: 568px) {
      .c-tbl__compare tbody td:last-child {
        border-bottom: 1px solid #CCCCCC; } }
    @media screen and (max-width: 568px) {
      .c-tbl__compare tbody td {
        display: block;
        padding: 0;
        font-size: 14px;
        border-bottom: none; } }
    @media screen and (max-width: 568px) {
      .c-tbl__compare tbody td:before {
        content: attr(data-tblName);
        font-weight: 600;
        display: block;
        padding: 10px 20px;
        text-align: center;
        border-bottom: 1px solid #CCCCCC;
        background-color: #F8F8F8; } }
    @media screen and (max-width: 568px) {
      .c-tbl__compare tbody td span {
        display: block;
        padding: 10px; } }
  .c-tbl__compare tbody th {
    background-color: #999;
    font-size: 22px; }
    @media screen and (max-width: 568px) {
      .c-tbl__compare tbody th {
        width: 100%;
        display: block;
        background-color: #2ec183;
        color: #FFFFFF;
        border-bottom: none; } }
  @media screen and (max-width: 568px) {
    .c-tbl__scroll {
      overflow: hidden;
      overflow-x: scroll;
      padding: 15px;
      border: 1px solid #cecece;
      border-radius: 5px; }
      .c-tbl__scroll:before {
        content: "←スライドで表の詳細を確認できます→";
        color: #666;
        font-size: 90%;
        display: block;
        margin-bottom: 10px;
        text-align: center; }
      .c-tbl__scroll table {
        min-width: 640px; }
        .c-tbl__scroll table th, .c-tbl__scroll table td {
          display: table-cell; }
        .c-tbl__scroll table th {
          white-space: nowrap; } }

.c-list {
  list-style-type: none;
  position: relative;
  padding-left: 1.5em;
  margin-bottom: 30px;
  line-height: 2; }
  .c-list:last-child {
    margin-bottom: 0; }
  .c-list--disc > .c-list__item {
    position: relative; }
    .c-list--disc > .c-list__item:before {
      content: '・';
      display: inline-block;
      color: #2ec183;
      position: absolute;
      top: 0;
      left: -1em;
      font-size: 1.5em;
      line-height: 1.5; }
      @media all and (-ms-high-contrast: none) {
        .c-list--disc > .c-list__item:before {
          top: -0.1em; } }
  .c-list--count {
    counter-reset: count; }
    .c-list--count > .c-list__item {
      position: relative; }
      .c-list--count > .c-list__item:before {
        counter-increment: count;
        content: counter(count);
        position: absolute;
        left: -1.1em;
        color: #2ec183; }
    .c-list--count--brackets {
      counter-reset: count; }
      .c-list--count--brackets > .c-list__item {
        position: relative; }
        .c-list--count--brackets > .c-list__item:before {
          counter-increment: count;
          content: "(" counter(count) ")";
          position: absolute;
          left: -1.5em;
          color: #2ec183; }
  .c-list--comment {
    margin-bottom: 30px; }
    .c-list--comment:last-child {
      margin-bottom: 0; }
    .c-list--comment > .c-list__item {
      position: relative; }
      .c-list--comment > .c-list__item:before {
        content: '※';
        display: inline-block;
        position: absolute;
        left: -1.5em; }
        @media all and (-ms-high-contrast: none) {
          .c-list--comment > .c-list__item:before {
            top: 0.1em; } }
    .c-list--comment--num {
      counter-reset: count; }
      .c-list--comment--num > .c-list__item {
        position: relative;
        padding-left: 0.5em; }
        .c-list--comment--num > .c-list__item:before {
          counter-increment: count;
          content: "※" counter(count);
          position: absolute;
          left: -1.5em; }
          @media all and (-ms-high-contrast: none) {
            .c-list--comment--num > .c-list__item:before {
              top: 0; } }
