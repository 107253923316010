//footerカスタマイズ
.l-footer {
  .copyright {
    .copyright-left {
      ul.bk-copyright-menu {
        li {
          // padding: 5px 12px;
          @include mq-max(lg) {
            padding: 5px 8px;
          }
        }
      }
    }

    .copyright-right {
      white-space: nowrap;
    }
  }
}