//PCナビ
.mainmenu-wrapper {
  .page_nav {
    .mainmenu {
      &>li {
        @include mq-min(content) {
          margin-right: 10px;
          &:last-child {
            margin-right: 0;
          }
        }
        &>a {
          padding-right: 0px !important;
          &>span {
            color: $black;
            text-shadow: 1px 1px 2px rgba(255,255,255,.5);
          }
          &.current {
            &>span {
              &:after {
                width: 100% !important;
              }
            }
          }
        }
      }
    }
  }
}


//スマホメニュー（サイド）
.popup-mobile-manu {
  .inner {
    .mobileheader {
      .logo {
        a {
          img {
            width: 180px;
          }
        }
      }
    }
  }
}