.c-cvArea {
  background-color: $yellow;
  &__heading {
    font-size: 28px;
    font-family: $sanserif;
    @include mq-max(bp991) {
      font-size: 22px;
    }
  }

  //パーツ調整
  .brook-btn {
    max-width: 314px;
    width: 100%;
  }
}