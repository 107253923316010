///////////////////////////////////////////////////////////////////////////////////////////////////////

//  ボタン

///////////////////////////////////////////////////////////////////////////////////////////////////////

//テンプレカスタム
.brook-btn {
  font-family: $sanserif !important;
}


.c-btn {
  &:hover {
    color: $white;
    background-color: $keyColor;
    &:after {
      right: 10px;
    }
  }
  &:after {
    @include fa('f054');
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    transition: right $transition;
  }
}


///////////////////////////////////////////////////////////////////////////////////////////////////////

//  PFWForm

///////////////////////////////////////////////////////////////////////////////////////////////////////
.l-btnArea{
  max-width: $contentWidth;
  margin: 40px auto;
  text-align: center;
  @include mq-max(md) {
    margin: 20px auto;
  }
  @include mq-max(sm) {
    width: $full-size;
    margin: 0 auto;
    padding: 10px 0;
  }
}

.c-btn {
  &.c-btnForm {
    display: inline-block;
    width: auto;
    min-width: $btn-width;
    padding: 20px 40px;
    margin-bottom: 10px;
    font-size: 130%;
    display: inline-block;
    background: $keyColor;
    text-align: center;
    border: $border-btn-width solid  $border-btn;
    color: $border-txt-color;
    transition: $transition;
    border-radius: $border-radius;
    text-decoration: none;
    position: relative;
    @include mq-max(sm) {
      display: inline-block;
      min-width: inherit;
      width: 100%;
      margin: 0 0 20px 0;
    }
    &:focus {
      outline: none;
    }

    &:hover{
      background: darken( $keyColor, 15% );
    }

    &.back{
      width: auto;
      line-height: 28px;
      min-width: inherit;
      background: $white;
      border: 1px solid #ccc;
      color: $black;
      font-size: 120%;
      padding-left: 60px;
      margin-right: 20px;
      @include mq-max(sm){
        font-size: 96%;
        min-width: inherit;
        width: 50%;
        max-width: 100%;
        margin: 0 auto;
        padding: 10px 20px;
      }

      &:hover{
        background: lighten( $gray, 15% );
      }

      &:before{
        @include fa('f053');
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translateY(-50%);
        text-align: left;
        @include mq-max(xs) {
          left: 8px;
        }
      }
      &:after {
        content: none;
      }
    }
  }
}
