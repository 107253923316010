/*
モジュール系おまとめファイル
*/

// ヘッダー
@import "header";

// フッター
@import "footer";

// グローバルナビゲーション
@import "navigation";

 // ボタン
@import "button";

//見出し
@import "heading";

//CVエリア
@import "cvArea";

//テーブル
@import "table";

//リスト
@import "list";